const env = process.env.NODE_ENV || "development";

const apiEnvironment = {
  development: {
    api: "https://uongbi2-api.cgis.asia/",
    media_url: "https://uongbi2-api.cgis.asia/",
    domainAdminSide: "http://localhost:3000",
    domainUserSide: "http://localhost:3000",
    domainName: "",
    workSpace: "phutho",
    wmsBaseLink: "https://geoserver.phutho.gov.vn/geoserver/phutho/wms/",
    datastore: 'phutho',
    gwcUrl: 'https://geoserver.phutho.gov.vn/geoserver/gwc/service/wms/'
  },
  production: {
    api: "https://uongbi2-api.cgis.asia/",
    media_url: "https://uongbi2-api.cgis.asia/",
    domainAdminSide: "https://admin.uongbi2.cgis.asia",
    domainUserSide: "https://uongbi2.cgis.asia",
    domainName: "uongbi2.cgis.asia",
    workSpace: "phutho",
    wmsBaseLink: "https://geoserver.phutho.gov.vn/geoserver/phutho/wms/",
    datastore: 'phutho',
    gwcUrl: 'https://geoserver.phutho.gov.vn/geoserver/gwc/service/wms/'
  }
};

module.exports = apiEnvironment[env];
