import React from "react";
import "./open-layer.scss";
import "ol/ol.css";
import { Map, View } from "ol";
import TileLayer from "ol/layer/Tile";
import { Image as ImageLayer, Layer } from "ol/layer";
import OSM from "ol/source/OSM";
import ImageWMS from "ol/source/ImageWMS";
import XYZ from "ol/source/XYZ";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as OpenlayerStore from "../../redux/store/openlayer/openlayer.store";
import OpenlayerModelCollect from "../../models/open-layer/props-state";
import BaseMapModels from "../../models/init-map-state/base-map";
import LayerModels from "../../models/init-map-state/layers";
import ControlFunctionType from "./control-function-type/control-function-type";
import TileWMS from "ol/source/TileWMS";
import { TileLayerClassName } from "../../modules/init-map-data/config/config";
import * as config from "../../utils/configuration";

class OpenlayerMapView extends React.Component<
  OpenlayerModelCollect.OpenlayerMapViewPropsModel,
  OpenlayerModelCollect.OpenlayerMapViewStateModel
> {
  constructor(props: any) {
    super(props);
    this.state = {
      map: null,
      mapContainerStyle: null,
    };
  }

  outSideHandleFunction = async (type: string, option: any) => {

    switch (type) {
      case ControlFunctionType.ToggleDisplayLayer:
        {
          console.log("ahihi", option);
          
          const LayerChange: LayerModels.layer_settingsModel = option.layer;
          const listLayerCollection = this.state.map.getLayers();
          listLayerCollection.forEach((layer: any, index: number) => {
            if(layer.getClassName() == TileLayerClassName(LayerChange.id)) {
              console.log("layerlayerlayerlayer", layer);
              layer.setVisible(!layer.getVisible());
            }
            if (LayerChange.geoTiff && layer.getClassName() == `layer-tiff-${LayerChange.geoTiff}`) {
              layer.setVisible(!layer.getVisible());
            }
          });
          
        }
        // {
        //   let indexRemove = -1;
        //   const LayerChange: LayerModels.layer_settingsModel = option.layer;
        //   console.log("LayerChange",LayerChange);
        //   const listLayerCollection = this.state.map.getLayers();
        //   listLayerCollection.forEach((event: any, index: number) => {
        //     if(!LayerChange.geoTiff) {
        //       if (
        //         event.values_.source.params_ &&
        //         event.values_.source.params_.LAYERS === `${LayerChange.table}`
        //       ) {
        //         indexRemove = index;
        //         return;
        //       }
        //     } else {
        //       if (
        //         event.values_.source.params_
        //       ) {
        //         console.log('event.values_.source.params_.LAYERS',event.values_.source.params_.LAYERS);
        //       }
        //       if (
        //         event.values_.source.params_ &&
        //         event.values_.source.params_.LAYERS.includes(`${LayerChange.table}`)
        //       ) {
        //         console.log("else if 1");
        //         indexRemove = index;
        //       }
        //     }
        //   });
        //   console.log('indexRemove',indexRemove);
        //   console.log("listLayerCollection",listLayerCollection);
        //   if (indexRemove !== -1) {
        //     listLayerCollection.forEach((layer: any, index: number) => {
        //       if(indexRemove == index) {
        //         console.log('check indexRemove listLayerCollection', layer);
        //         this.state.map.removeLayer(layer);
        //       }
        //     });
        //   } 
        //   else {
        //     console.log("PUSH shape");
        //     listLayerCollection.push(
        //       new TileLayer({
        //         visible: true,
        //         zIndex: LayerChange.zIndex + 100,
        //         minZoom: LayerChange.minZoom,
        //         maxZoom: LayerChange.maxZoom,
        //         source: new TileWMS({
        //           url: config.gwcUrl,
        //           params: {
        //             LAYERS: `${config.DataStore}:${LayerChange.table}`,
        //             LayerId: LayerChange.id,
        //             FORMAT: "image/png",
        //             VERSION: "1.1.0",
        //           },
        //           crossOrigin: "anonymous",
        //         }),
        //         className: TileLayerClassName(LayerChange.id),
        //       })
        //     );
        //   }
        //   console.log('indexTiffRemove',indexTiffRemove);
        // }
        break;

      case ControlFunctionType.SelectDisplayBasemap:
        {
          
          const BaseMapChange: BaseMapModels.base_mapsModel = option.basemap;
          const listLayerCollection = this.state.map.getLayers();

          if (BaseMapChange.baseMapSettingModel.layer_type !== "NONE") {
            listLayerCollection.insertAt(
              0,
              new TileLayer({
                source: new XYZ({
                  url: BaseMapChange.url,
                }),
              })
            );
            listLayerCollection.removeAt(1);
          } else {
            listLayerCollection.item(0).setVisible(false);
          }
        }
        break;
      
      case ControlFunctionType.AddAttachedTiffLayer:
        {
          const LayerChange: LayerModels.layer_settingsModel = option.layer;
          const listLayerCollection = this.state.map.getLayers();
          
          console.log('AddAttachedTiffLayer LayerChange',LayerChange);

          //xóa lớp geoTiff cũ
          if(LayerChange.geoTiffOld) {
            listLayerCollection.forEach((layer: any, index: number) => {
              if (LayerChange.geoTiffOld   && layer && layer.getClassName() == `layer-tiff-${LayerChange.geoTiffOld}`) {
                this.state.map.removeLayer(layer);
              }
            });
          }

          //thêm lớp geoTiff mới
          if(LayerChange.geoTiff) {
            const getNewTiffLayer = async (LayerChange: LayerModels.layer_settingsModel) => {
              return new TileLayer({
              visible: LayerChange.is_check || LayerChange.isCheck || false,
              zIndex: LayerChange.zindex || LayerChange.zIndex,
              minZoom: LayerChange.minzoom || LayerChange.minZoom,
              maxZoom: LayerChange.maxzoom || LayerChange.maxZoom,
              source: new TileWMS({
                // url : config.gwcUrl,
                url : LayerChange.wms,
                params: {
                  LAYERS: `${config.DataStore}:${LayerChange.geoTiff}`,
                  // LayerId: layerData.id,
                  FORMAT: "image/png",
                  VERSION: "1.1.0",
                },
                crossOrigin: 'anonymous'
              }),
              className: `layer-tiff-${LayerChange.geoTiff}`,
            })}
  
            const newTiffLayer = await getNewTiffLayer(LayerChange);
            
            if(LayerChange.geoTiff) {
              this.state.map.addLayer(newTiffLayer);
            }
          }
        }
        break;
      
        case ControlFunctionType.DeleteLayer:
          {
            const LayerChange: LayerModels.layer_settingsModel = option.layer;
            const listLayerCollection = this.state.map.getLayers();
            
            let indexLayerToRemoved : number[] = [];
            listLayerCollection.forEach((layer: any, index: number) => {
              if (layer.values_.source.params_ &&
                layer.values_.source.params_.LAYERS == (`${config.DataStore}:${LayerChange.table}`))
              {
                console.log("run if 1");
                
                indexLayerToRemoved.push(index);
                return;
              }

              if (layer.values_.source.params_ &&
                layer.values_.source.params_.LAYERS == (`${config.DataStore}:${LayerChange.geoTiff}`))
              {
                console.log("run if 2");
                
                indexLayerToRemoved.push(index);
                return;
              }
            });

            // sort mảng index theo thứ tự giảm dần
            indexLayerToRemoved.sort((a, b) => b - a);
            
            indexLayerToRemoved.forEach((index) => {
              const layerToRemove = listLayerCollection.item(index);
              if (layerToRemove) {
                this.state.map.removeLayer(layerToRemove);
              }
            });
          }
          break;

      default: {
        // console.log('have un set type:' + type)
        // console.log(option)
      }
    }
  };

  componentDidMount() {
    const DefaultGroupLayer: any[] = [];
    let listBaseMap = this.props.baseMaps;
    let listLayerGroup = this.props.layers.layer_categories;

    listBaseMap.base_maps.map((baseMap: any) => {
      if (baseMap.view_default) {
        DefaultGroupLayer.push(
          new TileLayer({
            source: new XYZ({
              url: baseMap.url,
              crossOrigin: "anonymous",
            }),
          })
        );
      }
    });

    // get
    let defaultListLayer: any = [];
    if (this.props.layers.haveData) {
      this.props.layers.layer_categories.map((layer_category_item) => {
        layer_category_item.layer_settings.map((layer_setting_item) => {
          defaultListLayer.push(layer_setting_item);
          if(layer_setting_item.layerRealationships.length > 0) {
            layer_setting_item.layerRealationships.map((layerRealationship_item) => {
              defaultListLayer.push(layerRealationship_item);
            })
          }
        });
      });
    }
    
    defaultListLayer.map((layerData: any, index: any) => {
        const layerImage: any = new TileLayer({
          visible: layerData.is_check,
          zIndex: layerData.zindex + 100,
          minZoom: layerData.minZoom,
          maxZoom: layerData.maxZoom,
          source: new TileWMS({
            // url: config.gwcUrl,
            url: layerData.wms,
            params: {
              LAYERS: `${config.DataStore}:${layerData.table}`,
              LayerId: layerData.id,
              FORMAT: "image/png",
              VERSION: "1.1.0",
            },
            crossOrigin: "anonymous",
          }),
          className: TileLayerClassName(layerData.id),
        });
        if(layerData.geoTiff) {
          const layerTiff: any = new TileLayer({
            visible: layerData.is_check,
            zIndex: layerData.zindex,
            minZoom: layerData.minZoom,
            maxZoom: layerData.maxZoom,
            source: new TileWMS({
              // url : config.gwcUrl,
              url : layerData.wms,
              params: {
                LAYERS: `${config.DataStore}:${layerData.geoTiff}`,
                // LayerId: layerData.id,
                FORMAT: "image/png",
                VERSION: "1.1.0",
              },
              crossOrigin: 'anonymous'
            }),
            className: `layer-tiff-${layerData.geoTiff}`,
          });
          DefaultGroupLayer.push(layerTiff);
        }
        DefaultGroupLayer.push(layerImage);
    });

    

    console.log("ImageLayer listLayerGroup",defaultListLayer);
    
    // listLayerGroup.map((layerGroup: any) => {
    //   layerGroup.layer_settings.map((layer: any) => {
    //     if (layer.is_check)
    //       DefaultGroupLayer.push(
    //         new ImageLayer({
    //           extent: this.props.mapSetting.extent,
    //           source: new ImageWMS({
    //             url: layer.wms,
    //             params: {
    //               FORMAT: "image/png",
    //               VERSION: "1.1.0",
    //               STYLES: "",
    //               LAYERS: `${layer.table}`,
    //             },
    //             ratio: 1,
    //             crossOrigin: "anonymous",
    //           }),
    //         })
    //       );
    //   });
    // });

    console.log("After add ImageLayer DefaultGroupLayer",DefaultGroupLayer);
    
    if (DefaultGroupLayer.length === 0) {
      DefaultGroupLayer.push(
        new TileLayer({
          source: new OSM(),
          visible: false,
        })
      );
    }


    
    this.props.SetOutSideHandleFunction(this.outSideHandleFunction);
    let mapOpenLayer = new Map({
      target: "map-container-id",
      layers: DefaultGroupLayer,
      view: new View({
        projection: this.props.mapSetting.projection,
        center: this.props.mapSetting.center,
        zoom: this.props.mapSetting.zoom,
      }),
    });
    this.setState({
      map: mapOpenLayer,
    }, () => {
      console.log("getLayers", mapOpenLayer.getLayers());
    });

    this.props.setMapLayer(mapOpenLayer);
    mapOpenLayer.on("moveend", this.onMoveEnd);
  }

  componentDidUpdate(
    prevProps: Readonly<OpenlayerModelCollect.OpenlayerMapViewPropsModel>,
    prevState: Readonly<OpenlayerModelCollect.OpenlayerMapViewStateModel>,
    snapshot?: any
  ): void {
    let defaultListLayer: any = [];
    if (this.props.layers.haveData) {
      this.props.layers.layer_categories.map((item) => {
        item.layer_settings.map((i) => {
          defaultListLayer.push(i);
        });
      });
    }

    defaultListLayer.map((layer: any) => {
      this.toggleDisplayVectorLayer(layer);
    });
  }

  toggleDisplayVectorLayer = (layer: any) => {
    const LayersCurrents = this.state.map?.getLayers();
    let hasLayer = LayersCurrents.array_.find((x: any) => x.className_ === `title-layer-${layer.id}`);
    LayersCurrents?.forEach((layerModel: Layer) => {
      if (layerModel.getClassName() === `title-layer-${layer.id}`) {
        layerModel.setVisible(layer.is_check);
        return;
      }
    });

    if (!hasLayer) {
      // add new layer
      const layerImage: any = new TileLayer({
        visible: false,
        zIndex: layer.z_index,
        minZoom: layer.min_zoom,
        maxZoom: layer.max_zoom,
        source: new TileWMS({
          // url: config.gwcUrl,
          url: layer.wms,
          params: {
            LAYERS: `${config.DataStore}:${layer.table}`,
            LayerId: layer.id,
            FORMAT: "image/png",
            VERSION: "1.1.0",
          },
          crossOrigin: "anonymous",
        }),
        className: TileLayerClassName(layer.id),
      });

      this.state.map.addLayer(layerImage);
    }
  };

  onMoveEnd = (event: any) => {
    let map = event.map;
    let extend = map?.getView().calculateExtent(map.getSize());
    let zoom = map?.getView().getZoom();
    this.props.setExtend(extend);
    this.props.setZoom(Math.round(zoom));
  };

  render() {
    return (
      <div className="h-100" id="map-container-id">
        {/* <MapToolPanel map={this.state.map} /> */}
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  mapSetting: state.initMap.mapSetting,
  baseMaps: state.initMap.baseMaps,
  layers: state.initMap.layers,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      SetOutSideHandleFunction: OpenlayerStore.SetHandleOutsideFunction,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(OpenlayerMapView);
